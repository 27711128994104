import * as React from 'react'


export default () => (
  <svg
    height="inherit"
    width="inherit"
    viewBox="0 0 1006 641"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      transform="translate(-326.000000, -1166.000000) translate(0.000000, 1011.000000) translate(324.000000, 147.000000)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(599.000000, 0.000000)">
        <g transform="translate(0.000000, 74.000000)" className="soln-arrows-animation">
          <path
            d="M14.928 142.105L.751 129.483l2.699-3.201 6.585 5.99c1.402 1.342 2.408 2.455 3.018 3.339V98.249h3.75v37.407c.427-.67.838-1.219 1.235-1.646.396-.427.99-1.006 1.783-1.738l6.585-5.99 2.699 3.2-14.177 12.623z"
            fill="#1890FF"
          />
          <path
            d="M14.926 116.259V95.636c0-11.045 8.957-19.998 20.006-19.998h39.392H39h99.162c11.042 0 19.994-8.955 19.994-19.997V19.997C158.156 8.953 167.103 0 178.159 0h96.165"
            stroke="#1890FF"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g transform="translate(321.000000, 0.000000)">
          <text
            fontFamily="DINPro-Bold, DINPro"
            fontSize={28}
            fontWeight="bold"
            letterSpacing="1.12"
            fill="#000"
          >
            <tspan x={13} y={29}>
              Sites
          </tspan>
          </text>
          <text
            fontFamily="DINPro-Regular, DINPro"
            fontSize={20}
            fontWeight="normal"
            letterSpacing="0.8"
            fill="#000"
          >
            <tspan x={0} y={149}>
              Raw Data
          </tspan>
          </text>
          <g
            className="soln-inputs"
          >
            <g
              transform="translate(7.000000, 44.000000)"
              fill="#FFF"
              fillRule="nonzero"
            >
              <circle cx={43} cy={36} r={36} />
              <rect x={0} y={33} width={23} height={27} />
            </g>
            <path
              d="M50.655 43c-11.4.044-22.855 5.207-30.172 15.794-1.045 1.48-1.917 3.224-2.7 4.792l-2.353 5.577h9.844c1.655-7.233 13.153-16.817 23.868-16.817 11.15 0 18.815 3.746 24.565 11.937 10.017 14.639 3.92 35.9-14.548 42.086-7.23 2.44-22.735 1.22-32.056 1.22.697 2.615 7.23 5.664 9.408 6.535 10.018 3.747 19.164 4.009 29.094-.26 2.44-1.046 3.66-1.918 5.75-3.225 8.798-5.925 16.202-17.34 16.202-27.883 0-9.846-.958-16.382-7.056-24.834-7.1-9.89-18.445-14.965-29.846-14.922zm1.293 13.114c-7.492 0-14.896 2.764-19.251 8.293-1.133 1.382-2.788 3.283-3.223 5.097h2.787c9.147-17.018 38.154-14.34 40.68 9.934.61 6.048-4.704 16.587-7.84 16.587H7v7.861h47.3c10.366 0 21.08-10.712 21.08-24.62 0-12.008-11.324-23.152-23.432-23.152zM45.93 72.038c-4.844 0-9.425 3.934-9.865 9.53-.44 4.983 4.58 10.14 9.337 10.14 14.182 0 13.125-19.67.528-19.67zM7 76.722v14.987h20.286c10.186 0 10.8-14.987-.44-14.987H7z"
              fill="#000"
            />
          </g>
        </g>
      </g>
      <g transform="translate(297.500000, 145.500000) scale(-1, 1) translate(-297.500000, -145.500000) translate(160.000000, 74.000000)">
        <path
          d="M14.928 142.105L.751 129.483l2.699-3.201 6.585 5.99c1.402 1.342 2.408 2.455 3.018 3.339V98.249h3.75v37.407c.427-.67.838-1.219 1.235-1.646.396-.427.99-1.006 1.783-1.738l6.585-5.99 2.699 3.2-14.177 12.623z"
          fill="#1890FF"
        />
        <path
          d="M14.926 116.259V95.636c0-11.045 8.957-19.998 20.006-19.998h39.392H39h99.162c11.042 0 19.994-8.955 19.994-19.997V19.997C158.156 8.953 167.103 0 178.159 0h96.165"
          stroke="#1890FF"
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="soln-arrows-animation"
        />
      </g>
      <g transform="translate(28.000000, 42.000000)" className="soln-inputs">
        <g transform="translate(18.694737, 0.000000)">
          <rect
            fill="#FFF"
            fillRule="nonzero"
            x="0.778947368"
            y="6.23157895"
            width="43.6210526"
            height="42.0631579"
          />
          <path
            d="M44.689 20.817c.065 0 .194-.064.259-.064V6.175C44.948.064 23.575 0 22.668 0 21.762 0 .388.064.388 6.175v14.514c.13 0 .195.064.26.128.971.318 22.603 7.448 44.04 0zM3.303 5.984c.13-.127.324-.255.583-.382.194-.127.389-.191.648-.318.194-.128.453-.191.777-.319C7.124 4.33 12.5 2.737 22.668 2.737c9.78 0 15.09 1.465 16.969 2.037 1.943.637 2.526 1.21 2.526 1.337l-.194.191c-.065.064-.195.128-.26.191l-.064.064c-.13.064-.26.127-.454.255l-.13.063c-.129.064-.323.128-.517.191l-.195.064c-.194.064-.388.127-.647.254l-.194.064c-.26.064-.454.127-.713.255l-.324.063c-.259.064-.518.128-.842.191l-.388.064-.972.191c-.194.064-.453.064-.712.127l-.648.128c-.194.063-.453.063-.648.127h-.13c-.258.064-.452.064-.712.127-.259.064-.518.064-.841.128-.26.063-.583.063-.842.127-.26 0-.583.064-.842.064-.324.063-.648.063-.972.063-.259 0-.583.064-.842.064-.388 0-.777.064-1.166.064l-.842.063c-.453 0-.906.064-1.36.064H19.82c-.454 0-.907 0-1.36-.064l-.843-.063c-.388 0-.777-.064-1.165-.064-.26 0-.583-.064-.842-.064-.324 0-.648-.063-.972-.063-.259 0-.583-.064-.842-.064-.324-.064-.583-.064-.842-.127-.259-.064-.583-.064-.842-.128-.259-.063-.453-.063-.712-.127l-.389.127c-.194 0-.388-.063-.583-.063L9.78 8.53c-.26-.063-.518-.063-.713-.127l-.971-.191-.389-.064a17.293 17.293 0 0 1-.842-.19l-.324-.128c-.259-.064-.518-.127-.712-.255l-.26-.063-.582-.191-.194-.064a1.953 1.953 0 0 1-.518-.254l-.065-.064c-.13-.064-.324-.127-.454-.255l-.064-.063c-.13-.064-.195-.128-.324-.255l-.13-.127c-.13-.064-.064-.128.065-.255z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M3.303 5.984c.13-.127.324-.255.583-.382.194-.127.389-.191.648-.318.194-.128.453-.191.777-.319C7.124 4.33 12.5 2.737 22.668 2.737c9.78 0 15.09 1.465 16.969 2.037 1.943.637 2.526 1.21 2.526 1.337l-.194.191c-.065.064-.195.128-.26.191l-.064.064c-.13.064-.26.127-.454.255l-.13.063c-.129.064-.323.128-.517.191l-.195.064c-.194.064-.388.127-.647.254l-.194.064c-.26.064-.454.127-.713.255l-.324.063c-.259.064-.518.128-.842.191l-.388.064-.972.191c-.194.064-.453.064-.712.127l-.648.128c-.194.063-.453.063-.648.127h-.13c-.258.064-.452.064-.712.127-.259.064-.518.064-.841.128-.26.063-.583.063-.842.127-.26 0-.583.064-.842.064-.324.063-.648.063-.972.063-.259 0-.583.064-.842.064-.388 0-.777.064-1.166.064l-.842.063c-.453 0-.906.064-1.36.064H19.82c-.454 0-.907 0-1.36-.064l-.843-.063c-.388 0-.777-.064-1.165-.064-.26 0-.583-.064-.842-.064-.324 0-.648-.063-.972-.063-.259 0-.583-.064-.842-.064-.324-.064-.583-.064-.842-.127-.259-.064-.583-.064-.842-.128-.259-.063-.453-.063-.712-.127l-.389.127c-.194 0-.388-.063-.583-.063L9.78 8.53c-.26-.063-.518-.063-.713-.127l-.971-.191-.389-.064a17.293 17.293 0 0 1-.842-.19l-.324-.128c-.259-.064-.518-.127-.712-.255l-.26-.063-.582-.191-.194-.064a1.953 1.953 0 0 1-.518-.254l-.065-.064c-.13-.064-.324-.127-.454-.255l-.064-.063c-.13-.064-.195-.128-.324-.255l-.13-.127c-.13-.064-.064-.128.065-.255z"
            fill="#FFF"
            fillRule="nonzero"
            id="cutout"
          />
          <path
            d="M44.818 36.859c.065 0 .13 0 .195-.064V23.618c-7.384 2.419-14.832 3.31-21.503 3.31-11.01 0-19.948-2.292-23.057-3.31v13.368c4.016 1.337 24.612 7.385 44.365-.127z"
            fill="#000"
          />
          <path
            d="M.389 39.787v11.331c0 6.112 21.373 6.175 22.28 6.175.906 0 22.279-.063 22.279-6.175V39.723c-7.448 2.674-14.832 3.629-21.503 3.629C12.89 43.352 4.21 41.06.39 39.787z"
            fill="#000"
          />
        </g>
        <g transform="translate(42.063158, 7.789474)">
          <rect
            fill="#FFF"
            fillRule="nonzero"
            x="0.778947368"
            y="6.23157895"
            width="43.6210526"
            height="42.0631579"
          />
          <path
            d="M44.689 20.817c.065 0 .194-.064.259-.064V6.175C44.948.064 23.575 0 22.668 0 21.762 0 .388.064.388 6.175v14.514c.13 0 .195.064.26.128.971.318 22.603 7.448 44.04 0zM3.303 5.984c.13-.127.324-.255.583-.382.194-.127.389-.191.648-.318.194-.128.453-.191.777-.319C7.124 4.33 12.5 2.737 22.668 2.737c9.78 0 15.09 1.465 16.969 2.037 1.943.637 2.526 1.21 2.526 1.337l-.194.191c-.065.064-.195.128-.26.191l-.064.064c-.13.064-.26.127-.454.255l-.13.063c-.129.064-.323.128-.517.191l-.195.064c-.194.064-.388.127-.647.254l-.194.064c-.26.064-.454.127-.713.255l-.324.063c-.259.064-.518.128-.842.191l-.388.064-.972.191c-.194.064-.453.064-.712.127l-.648.128c-.194.063-.453.063-.648.127h-.13c-.258.064-.452.064-.712.127-.259.064-.518.064-.841.128-.26.063-.583.063-.842.127-.26 0-.583.064-.842.064-.324.063-.648.063-.972.063-.259 0-.583.064-.842.064-.388 0-.777.064-1.166.064l-.842.063c-.453 0-.906.064-1.36.064H19.82c-.454 0-.907 0-1.36-.064l-.843-.063c-.388 0-.777-.064-1.165-.064-.26 0-.583-.064-.842-.064-.324 0-.648-.063-.972-.063-.259 0-.583-.064-.842-.064-.324-.064-.583-.064-.842-.127-.259-.064-.583-.064-.842-.128-.259-.063-.453-.063-.712-.127l-.389.127c-.194 0-.388-.063-.583-.063L9.78 8.53c-.26-.063-.518-.063-.713-.127l-.971-.191-.389-.064a17.293 17.293 0 0 1-.842-.19l-.324-.128c-.259-.064-.518-.127-.712-.255l-.26-.063-.582-.191-.194-.064a1.953 1.953 0 0 1-.518-.254l-.065-.064c-.13-.064-.324-.127-.454-.255l-.064-.063c-.13-.064-.195-.128-.324-.255l-.13-.127c-.13-.064-.064-.128.065-.255z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M3.303 5.984c.13-.127.324-.255.583-.382.194-.127.389-.191.648-.318.194-.128.453-.191.777-.319C7.124 4.33 12.5 2.737 22.668 2.737c9.78 0 15.09 1.465 16.969 2.037 1.943.637 2.526 1.21 2.526 1.337l-.194.191c-.065.064-.195.128-.26.191l-.064.064c-.13.064-.26.127-.454.255l-.13.063c-.129.064-.323.128-.517.191l-.195.064c-.194.064-.388.127-.647.254l-.194.064c-.26.064-.454.127-.713.255l-.324.063c-.259.064-.518.128-.842.191l-.388.064-.972.191c-.194.064-.453.064-.712.127l-.648.128c-.194.063-.453.063-.648.127h-.13c-.258.064-.452.064-.712.127-.259.064-.518.064-.841.128-.26.063-.583.063-.842.127-.26 0-.583.064-.842.064-.324.063-.648.063-.972.063-.259 0-.583.064-.842.064-.388 0-.777.064-1.166.064l-.842.063c-.453 0-.906.064-1.36.064H19.82c-.454 0-.907 0-1.36-.064l-.843-.063c-.388 0-.777-.064-1.165-.064-.26 0-.583-.064-.842-.064-.324 0-.648-.063-.972-.063-.259 0-.583-.064-.842-.064-.324-.064-.583-.064-.842-.127-.259-.064-.583-.064-.842-.128-.259-.063-.453-.063-.712-.127l-.389.127c-.194 0-.388-.063-.583-.063L9.78 8.53c-.26-.063-.518-.063-.713-.127l-.971-.191-.389-.064a17.293 17.293 0 0 1-.842-.19l-.324-.128c-.259-.064-.518-.127-.712-.255l-.26-.063-.582-.191-.194-.064a1.953 1.953 0 0 1-.518-.254l-.065-.064c-.13-.064-.324-.127-.454-.255l-.064-.063c-.13-.064-.195-.128-.324-.255l-.13-.127c-.13-.064-.064-.128.065-.255z"
            fill="#FFF"
            fillRule="nonzero"
            id="cutout"
          />
          <path
            d="M44.818 36.859c.065 0 .13 0 .195-.064V23.618c-7.384 2.419-14.832 3.31-21.503 3.31-11.01 0-19.948-2.292-23.057-3.31v13.368c4.016 1.337 24.612 7.385 44.365-.127z"
            fill="#000"
          />
          <path
            d="M.389 39.787v11.331c0 6.112 21.373 6.175 22.28 6.175.906 0 22.279-.063 22.279-6.175V39.723c-7.448 2.674-14.832 3.629-21.503 3.629C12.89 43.352 4.21 41.06.39 39.787z"
            fill="#000"
          />
        </g>
        <g transform="translate(0.000000, 16.357895)">
          <rect
            fill="#FFF"
            fillRule="nonzero"
            x="0.778947368"
            y="6.23157895"
            width="43.6210526"
            height="42.0631579"
          />
          <path
            d="M44.689 20.817c.065 0 .194-.064.259-.064V6.175C44.948.064 23.575 0 22.668 0 21.762 0 .388.064.388 6.175v14.514c.13 0 .195.064.26.128.971.318 22.603 7.448 44.04 0zM3.303 5.984c.13-.127.324-.255.583-.382.194-.127.389-.191.648-.318.194-.128.453-.191.777-.319C7.124 4.33 12.5 2.737 22.668 2.737c9.78 0 15.09 1.465 16.969 2.037 1.943.637 2.526 1.21 2.526 1.337l-.194.191c-.065.064-.195.128-.26.191l-.064.064c-.13.064-.26.127-.454.255l-.13.063c-.129.064-.323.128-.517.191l-.195.064c-.194.064-.388.127-.647.254l-.194.064c-.26.064-.454.127-.713.255l-.324.063c-.259.064-.518.128-.842.191l-.388.064-.972.191c-.194.064-.453.064-.712.127l-.648.128c-.194.063-.453.063-.648.127h-.13c-.258.064-.452.064-.712.127-.259.064-.518.064-.841.128-.26.063-.583.063-.842.127-.26 0-.583.064-.842.064-.324.063-.648.063-.972.063-.259 0-.583.064-.842.064-.388 0-.777.064-1.166.064l-.842.063c-.453 0-.906.064-1.36.064H19.82c-.454 0-.907 0-1.36-.064l-.843-.063c-.388 0-.777-.064-1.165-.064-.26 0-.583-.064-.842-.064-.324 0-.648-.063-.972-.063-.259 0-.583-.064-.842-.064-.324-.064-.583-.064-.842-.127-.259-.064-.583-.064-.842-.128-.259-.063-.453-.063-.712-.127l-.389.127c-.194 0-.388-.063-.583-.063L9.78 8.53c-.26-.063-.518-.063-.713-.127l-.971-.191-.389-.064a17.293 17.293 0 0 1-.842-.19l-.324-.128c-.259-.064-.518-.127-.712-.255l-.26-.063-.582-.191-.194-.064a1.953 1.953 0 0 1-.518-.254l-.065-.064c-.13-.064-.324-.127-.454-.255l-.064-.063c-.13-.064-.195-.128-.324-.255l-.13-.127c-.13-.064-.064-.128.065-.255z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M3.303 5.984c.13-.127.324-.255.583-.382.194-.127.389-.191.648-.318.194-.128.453-.191.777-.319C7.124 4.33 12.5 2.737 22.668 2.737c9.78 0 15.09 1.465 16.969 2.037 1.943.637 2.526 1.21 2.526 1.337l-.194.191c-.065.064-.195.128-.26.191l-.064.064c-.13.064-.26.127-.454.255l-.13.063c-.129.064-.323.128-.517.191l-.195.064c-.194.064-.388.127-.647.254l-.194.064c-.26.064-.454.127-.713.255l-.324.063c-.259.064-.518.128-.842.191l-.388.064-.972.191c-.194.064-.453.064-.712.127l-.648.128c-.194.063-.453.063-.648.127h-.13c-.258.064-.452.064-.712.127-.259.064-.518.064-.841.128-.26.063-.583.063-.842.127-.26 0-.583.064-.842.064-.324.063-.648.063-.972.063-.259 0-.583.064-.842.064-.388 0-.777.064-1.166.064l-.842.063c-.453 0-.906.064-1.36.064H19.82c-.454 0-.907 0-1.36-.064l-.843-.063c-.388 0-.777-.064-1.165-.064-.26 0-.583-.064-.842-.064-.324 0-.648-.063-.972-.063-.259 0-.583-.064-.842-.064-.324-.064-.583-.064-.842-.127-.259-.064-.583-.064-.842-.128-.259-.063-.453-.063-.712-.127l-.389.127c-.194 0-.388-.063-.583-.063L9.78 8.53c-.26-.063-.518-.063-.713-.127l-.971-.191-.389-.064a17.293 17.293 0 0 1-.842-.19l-.324-.128c-.259-.064-.518-.127-.712-.255l-.26-.063-.582-.191-.194-.064a1.953 1.953 0 0 1-.518-.254l-.065-.064c-.13-.064-.324-.127-.454-.255l-.064-.063c-.13-.064-.195-.128-.324-.255l-.13-.127c-.13-.064-.064-.128.065-.255z"
            fill="#FFF"
            fillRule="nonzero"
            id="cutout"
          />
          <path
            d="M44.818 36.859c.065 0 .13 0 .195-.064V23.618c-7.384 2.419-14.832 3.31-21.503 3.31-11.01 0-19.948-2.292-23.057-3.31v13.368c4.016 1.337 24.612 7.385 44.365-.127z"
            fill="#000"
          />
          <path
            d="M.389 39.787v11.331c0 6.112 21.373 6.175 22.28 6.175.906 0 22.279-.063 22.279-6.175V39.723c-7.448 2.674-14.832 3.629-21.503 3.629C12.89 43.352 4.21 41.06.39 39.787z"
            fill="#000"
          />
        </g>
      </g>
      <text
        fontFamily="DINPro-Bold, DINPro"
        fontSize={28}
        fontWeight="bold"
        letterSpacing="1.12"
        fill="#000"
      >
        <tspan x={18} y={29}>
          Pharma
      </tspan>
      </text>
      <text
        fontFamily="DINPro-Regular, DINPro"
        fontSize={20}
        fontWeight="normal"
        letterSpacing="0.8"
        fill="#000"
      >
        <tspan x={16} y={149}>
          Mixed Data
      </tspan>
      </text>
      <g transform="translate(455.000000, 0.000000)">
        <path
          d="M61.928 217.105l-14.177-12.622 2.699-3.201 6.585 5.99c1.402 1.342 2.408 2.455 3.018 3.339v-37.362h3.75v37.407c.427-.67.838-1.219 1.235-1.646.396-.427.99-1.006 1.783-1.738l6.585-5.99 2.699 3.2-14.177 12.623z"
          fill="#1890FF"
        />
        <g
          transform="translate(11.000000, 42.000000)"
          fillRule="nonzero"
          className="soln-inputs"
        >
          <rect
            fill="#FFF"
            x={0}
            y={0}
            width={101}
            height={74}
            rx={7}
          />
          <path
            d="M31.394 55.94H0V39.12h31.394V55.94zm2.242 0h33.637V39.12H33.636V55.94zm0 19.06h33.637V58.182H33.636V75zm-2.242-54.94H0V36.88h31.394V20.06zm2.242 16.819h33.637V20.06H33.636v16.818zM93.061 1H7.848A7.848 7.848 0 0 0 0 8.848v8.97h100.91v-8.97A7.848 7.848 0 0 0 93.06 1zM69.515 36.879h31.394V20.06H69.515v16.818zM31.394 58.182H0v8.97A7.848 7.848 0 0 0 7.848 75h23.546V58.182zm38.121-2.243h31.394V39.121H69.515V55.94zm0 19.061h23.546a7.848 7.848 0 0 0 7.848-7.848v-8.97H69.515V75z"
            fill="#000"
          />
        </g>
        <text
          fontFamily="DINPro-Regular, DINPro"
          fontSize={20}
          fontWeight="normal"
          letterSpacing="0.8"
          fill="#000"
        >
          <tspan x="0.79" y={149}>
            Clinical Data
        </tspan>
        </text>
        <text
          fontFamily="DINPro-Bold, DINPro"
          fontSize={28}
          fontWeight="bold"
          letterSpacing="1.12"
          fill="#000"
        >
          <tspan x={31} y={29}>
            CROs
        </tspan>
        </text>
      </g>
      <g transform="translate(517.500000, 500.500000) rotate(90.000000) translate(-517.500000, -500.500000) translate(369.000000, 486.000000)">
        <path
          d="M251 19H0"
          stroke="#1890FF"
          strokeWidth="3.71"
          strokeLinecap="square"
        />
        <path
          d="M251 10H0"
          stroke="#1890FF"
          strokeWidth="3.71"
          strokeLinecap="square"
        />
        <path
          d="M297 14.5L283.61 29l-3.396-2.76 2.862-3.04c.938-.967 1.892-1.73 2.863-2.292H248V17.12h40.85l2.474-2.619-2.474-2.62H248V8.093h37.939c-.615-.343-1.124-.694-1.529-1.052a25.407 25.407 0 0 1-1.334-1.287l-2.862-2.993L283.61 0 297 14.5z"
          fill="#1890FF"
        />
      </g>
      <g transform="translate(517.500000, 372.500000) scale(-1, 1) translate(-517.500000, -372.500000) translate(388.000000, 225.000000)">
        <path
          d="M89.02 161.285c1.715 2.757 2.744 6.433 2.744 10.11v111.198c0 4.136 1.372 7.812 4.116 10.11 1.373 1.378 3.431 2.297 5.146 2.297 1.03 0 2.402-.46 3.43-.919l57.634-32.165c3.43-1.838 5.49-6.433 5.49-11.028v-79.494c0-3.676 1.028-7.352 2.4-10.109l86.451-135.553c2.744-4.595 3.43-11.028 1.372-16.542C255.745 3.676 251.628 0 247.168 0H11.832C7.372 0 3.255 3.676 1.197 9.19-.862 14.704-.175 21.137 2.569 26.192l86.45 135.093zM7.622 12.671C8.311 10.377 10.031 9 12.094 9H248.05c2.064 0 3.784 1.377 4.472 3.671.687 2.295.687 5.048-.344 6.884l-86.678 135.381c-2.751 4.59-4.127 10.096-4.127 15.603v79.393c0 1.377-.688 2.295-1.376 2.754l-57.785 32.124c-1.032.459-1.72 0-2.063-.459-.344-.459-1.032-.918-1.032-2.295V170.998c0-5.507-1.376-11.473-4.128-16.062L7.967 19.556c-1.032-1.837-1.376-4.59-.344-6.885zm0 0C8.311 10.377 10.031 9 12.094 9H248.05c2.064 0 3.784 1.377 4.472 3.671.687 2.295.687 5.048-.344 6.884l-86.678 135.381c-2.751 4.59-4.127 10.096-4.127 15.603v79.393c0 1.377-.688 2.295-1.376 2.754l-57.785 32.124c-1.032.459-1.72 0-2.063-.459-.344-.459-1.032-.918-1.032-2.295V170.998c0-5.507-1.376-11.473-4.128-16.062L7.967 19.556c-1.032-1.837-1.376-4.59-.344-6.885z"
          fill="#001529"
          fillRule="nonzero"
        />
        <g transform="translate(128.500000, 129.500000) scale(-1, 1) translate(-128.500000, -129.500000) translate(93.000000, 18.000000)">
          <g transform="translate(23.000000, 0.000000)" fill="#FFF">
            <path d="M10.88 222.208l-10-8.689 1.903-2.204 4.645 4.125c.99.923 1.7 1.69 2.13 2.298v-25.722h2.645v25.753c.3-.462.591-.84.87-1.133.28-.294.7-.693 1.259-1.196l4.645-4.125 1.903 2.204-10 8.69z" />
            <path
              d="M10.886 143.522V192.5m0-192.11v29.887"
              stroke="#FFF"
              strokeWidth="2.72"
              fillRule="nonzero"
              strokeLinecap="square"
              className="soln-filter-animation"
            />
          </g>
          <g transform="translate(0.000000, 52.000000)">
            <g
              className="logo-spinner"
              style={{ transformOrigin: '3.5% 5.4%' }}
            >
              <path
                d="M29.636 54.939c-.302 0-.605-.108-.85-.326a132.569 132.569 0 0 1-6.582-6.34c-4.53-4.43-8.406-8.936-11.496-13.371a1.5 1.5 0 0 1-.312-.32c-2.69-3.97-4.91-7.975-6.594-11.903C-.044 13.657-.01 7.069 3.9 3.094 7.705-.9 14.167-1.03 23.097 2.724a159.38 159.38 0 0 1 5.486 2.415c.056.027.112.058.165.092.32.209.59.378.812.507a2.072 2.072 0 0 1 .487.297l.6.524c.539.47.6 1.295.138 1.844a1.274 1.274 0 0 1-1.814.14l-.483-.42-.075-.039c-.26-.146-.587-.346-.979-.601a157.807 157.807 0 0 0-5.318-2.34C14.255 1.838 8.744 1.767 5.733 4.929 2.628 8.084 2.777 13.7 6.16 21.636c1.592 3.714 3.69 7.509 6.233 11.282.169.137.347.32.474.56 2.988 4.273 6.737 8.624 11.145 12.934a128.507 128.507 0 0 0 6.477 6.238c.533.479.584 1.304.114 1.846-.254.293-.61.443-.966.443z"
                fill="#FFF"
                fillRule="nonzero"
              />
              <path
                d="M14.95 31.258a1.27 1.27 0 0 1-.74-.237 1.324 1.324 0 0 1-.313-1.824l1.117-1.617c.02-.026.039-.053.06-.078a76.434 76.434 0 0 1 6.333-6.883c4.377-4.33 8.755-8.007 13.006-10.916.064-.045.132-.083.205-.114 4.184-2.953 8.406-5.265 12.55-6.875 8.881-3.747 15.327-3.616 19.121.404 3.926 3.958 3.962 10.556.08 19.586a48.756 48.756 0 0 1-2.934 6.272 1.257 1.257 0 0 1-.123.183l-.558.698a1.272 1.272 0 0 1-1.812.19 1.321 1.321 0 0 1-.187-1.841l.487-.611a46.038 46.038 0 0 0 2.744-5.885c3.435-7.992 3.584-13.612.466-16.754-3.007-3.185-8.497-3.112-16.331.192-4.04 1.57-8.137 3.824-12.208 6.715a1.333 1.333 0 0 1-.237.133c-4.067 2.803-8.266 6.34-12.484 10.511a74.325 74.325 0 0 0-6.099 6.62l-1.089 1.575a1.277 1.277 0 0 1-1.054.556z"
                fill="#FFF"
                fillRule="nonzero"
              />
              <path
                d="M59.102 68.673c-3.15 0-6.866-.88-11.128-2.638a55.402 55.402 0 0 1-7.177-3.449l-.947-.587a1.3 1.3 0 0 1-.421-1.788 1.291 1.291 0 0 1 1.781-.42l.908.56a52.753 52.753 0 0 0 6.817 3.273c7.854 3.239 13.417 3.274 16.56.12 2.994-3.11 2.834-8.695-.494-16.59-1.838-4.17-4.359-8.442-7.483-12.681-2.906-3.94-6.448-7.997-10.524-12.063a354.172 354.172 0 0 0-7.456-6.88 1.301 1.301 0 0 1-.106-1.834 1.29 1.29 0 0 1 1.828-.105 350.019 350.019 0 0 1 7.533 6.954c4.191 4.18 7.819 8.337 10.804 12.382 3.237 4.393 5.854 8.832 7.78 13.194 3.779 8.965 3.76 15.5-.038 19.443-2.065 2.072-4.818 3.11-8.237 3.11z"
                fill="#FFF"
                fillRule="nonzero"
              />
              <path
                d="M11.091 68.673c-3.415 0-6.152-1.037-8.184-3.112-3.874-3.96-3.876-10.519-.007-19.494a65.84 65.84 0 0 1 3.217-6.517c.202-.491.494-.923.87-1.283l.505-.624a1.288 1.288 0 0 1 1.822-.19c.556.453.64 1.275.19 1.832l-.562.695c-.044.054-.09.102-.14.148a1.14 1.14 0 0 0-.304.452c-.024.06-.051.122-.083.178a63.145 63.145 0 0 0-3.134 6.337c-3.412 7.914-3.587 13.51-.526 16.638 3.093 3.16 8.65 3.119 16.513-.12 4.392-1.725 8.847-4.203 13.26-7.366.09-.06.19-.123.297-.176 3.83-2.735 7.73-6.077 11.594-9.933a120.6 120.6 0 0 0 7.02-7.394l1.215-1.726a1.292 1.292 0 0 1 1.806-.312c.584.416.724 1.228.31 1.817l-1.252 1.78c-.025.036-.053.07-.081.104A123.759 123.759 0 0 1 48.233 48c-3.996 3.986-8.047 7.45-12.033 10.285a1.256 1.256 0 0 1-.24.135c-4.546 3.255-9.172 5.823-13.73 7.614-4.273 1.758-7.993 2.64-11.139 2.64z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
            <polygon
              fill="#1890FF"
              points="41.5631893 24.1541078 41.5631893 27.2769734 32.2434943 27.2769734 32.2434943 32.7086876 41.1656668 32.7086876 41.1656668 35.8315533 32.2434943 35.8315533 32.2434943 41.7072768 41.7693121 41.7072768 41.7693121 44.8301425 28.268269 44.8301425 28.268269 24.1541078"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)